import React from 'react'

import "./AdPlaceHolder.css";

import { LoadingCircle } from '../LoadingCircle/LoadingCircle';

export const AdPlaceHolder = () => {

    const [loading, toggleLoading] = React.useState(true);

    React.useEffect(() => {
        setTimeout(() => {

            toggleLoading(false);

        }, 500)
    }, [])

    return (
        <div className='ad-place-holder'>
            {loading ? <LoadingCircle position='absolute' /> : null}

            <div className='ad-wrapper'>
            </div>
        </div>
    )
}
