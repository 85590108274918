import React from 'react'

import "./BuiltBy.css";

export const BuiltBy = () => {
    return (
        <div className='built-by-container'>
        <h3>Built With</h3> 
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_100_2)">
        <path d="M5.15625 0C4.37695 0 3.75 0.626953 3.75 1.40625C3.75 3.68555 5.12109 4.88672 6.04102 5.68945L6.10547 5.74805C7.06055 6.58008 7.5 7.02539 7.5 7.96875C7.5 8.74805 8.12695 9.375 8.90625 9.375C9.68555 9.375 10.3125 8.74805 10.3125 7.96875C10.3125 5.68945 8.94141 4.48828 8.02148 3.68555L7.95703 3.62695C7.00195 2.79492 6.5625 2.34961 6.5625 1.40625C6.5625 0.626953 5.93555 0 5.15625 0ZM1.875 11.25C0.837891 11.25 0 12.0879 0 13.125V24.375C0 27.4805 2.51953 30 5.625 30H16.875C19.9805 30 22.5 27.4805 22.5 24.375H23.4375C27.0645 24.375 30 21.4395 30 17.8125C30 14.1855 27.0645 11.25 23.4375 11.25H1.875ZM22.5 15H23.4375C24.9902 15 26.25 16.2598 26.25 17.8125C26.25 19.3652 24.9902 20.625 23.4375 20.625H22.5V15ZM13.125 1.40625C13.125 0.626953 12.498 0 11.7188 0C10.9395 0 10.3125 0.626953 10.3125 1.40625C10.3125 3.68555 11.6836 4.88672 12.6035 5.68945L12.668 5.74805C13.623 6.58008 14.0625 7.02539 14.0625 7.96875C14.0625 8.74805 14.6895 9.375 15.4688 9.375C16.248 9.375 16.875 8.74805 16.875 7.96875C16.875 5.68945 15.5039 4.48828 14.584 3.68555L14.5195 3.62695C13.5645 2.79492 13.125 2.34961 13.125 1.40625Z" fill="#F8F8F8"/>
        </g>
        <defs>
        <clipPath id="clip0_100_2">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        <h3>By: </h3>
        <a rel='noreferrer' target='_blank' href='https://norxwestdesigns.ca/'>Nor X West Designs</a>
        </div>
    )
}
