import React from 'react'

import {Image} from '../Image/Image';
import { wrap, AnimatePresence, motion } from 'framer-motion';

import "./Gallery.css";

export const Gallery = ({images = []}) => {

    const [[page, direction], setPage] = React.useState([0, 0]);
    
    const variants = {
        enter: (direction) => {
          return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
          };
        },
        center: {
          zIndex: 1,
          x: 0,
          opacity: 1
        },
        exit: (direction) => {
          return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
          };
        }
    };

    const swipeConfidenceThreshold = 10000;

    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    };

    const imageIndex = wrap(0, images.length, page)

    const paginate = (newDirection) => {    
        setPage([page + newDirection, newDirection]);
    };

    const handleKey = (e) => {
        if (e.keyCode === 39) {
            paginate(1)
        } else if (e.keyCode === 37) {
            paginate(-1)
        }
    }

    React.useEffect(() => {

        window.addEventListener('keyup', handleKey);

        return () => {
            window.removeEventListener('keyup', handleKey);
        }

    // eslint-disable-next-line
    }, [page, images])

    return (
        <div className='gallery-container'>
            <AnimatePresence initial={false} custom={direction} mode='popLayout'>
                <motion.div
                className='image-gallery-animation-wrapper'
                key={page}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                    duration: 0.2
                }}
                drag="x"
                dragConstraints={{ left: 0, right: 0 }}
                dragElastic={1}
                onDragEnd={(e, { offset, velocity }) => {
                    const swipe = swipePower(offset.x, velocity.x);

                    if (swipe < -swipeConfidenceThreshold) {
                    paginate(1);
                    } else if (swipe > swipeConfidenceThreshold) {
                    paginate(-1);
                    }
                }}
                >
                    <Image key={page} image={images[imageIndex]} objectFit='cover' />
                    <div onClick={() => {paginate(-1)}} className='left-gallery-control'>
                     
                    </div>
                    <div onClick={() => {paginate(1)}} className='right-gallery-control'>
                       
                    </div>
                </motion.div>
            </AnimatePresence>
            
            <div className='gallery-counter-indication'>
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_169_10)">
                <path d="M6.25 23.75V28.75H28.75V7.4625H23.75M2.5 22.5L8.75 15L12.5 18.75L17.5 12.5L23.75 20M1.25 1.25H23.75V23.75H1.25V1.25ZM7.5 10C7.83152 10 8.14946 9.8683 8.38388 9.63388C8.6183 9.39946 8.75 9.08152 8.75 8.75C8.75 8.41848 8.6183 8.10054 8.38388 7.86612C8.14946 7.6317 7.83152 7.5 7.5 7.5C7.16848 7.5 6.85054 7.6317 6.61612 7.86612C6.3817 8.10054 6.25 8.41848 6.25 8.75C6.25 9.08152 6.3817 9.39946 6.61612 9.63388C6.85054 9.8683 7.16848 10 7.5 10Z" stroke="white" strokeWidth="2.5"/>
                </g>
                <defs>
                <clipPath id="clip0_169_10">
                <rect width="30" height="30" fill="white"/>
                </clipPath>
                </defs>
                </svg>
                <h3>{imageIndex + 1} / {images.length}</h3>
            </div>
        </div>
    )
}
