import React from 'react'

import { motion } from 'framer-motion';

import "./CategoryButton.css";

export const CategoryButton = ({name, state, icon, path = "/", sort}) => {

    const navigate = (e) => {
        e.preventDefault();

        const current_sort_state = new URLSearchParams(window.location.search).get('sort');

        if (path === window.location.pathname || state) return;

        if (sort) {

            let new_url = window.location.protocol + '//' + window.location.host + window.location.pathname + path;

            window.location.href = new_url;

        } else {
            let new_url = window.location.protocol + '//' + window.location.host + path + (current_sort_state ? `?sort=${current_sort_state}` : '');

            window.location.href = new_url;
        }
        
    }

    return (
        <motion.a 
        href={path}
        style={{
        opacity: state ? 1 : window.location.pathname === path ? 1 : 0.5,
        cursor: state ? 'default' : window.location.pathname === path ? 'default' : 'pointer'
        }}
        rel='noreferrer'
        transition={{duration: 0.1}}
        whileTap={{opacity: 1, scale: 1.1}}
        whileHover={{scale: 1.05}}
        onClick={navigate} className='category-button'>
            <h2>{name}</h2>
            {icon}
        </motion.a>
    )
}
