import React from 'react'
import { Categories } from '../../Util/Categories'
import { Models } from '../../Util/Models';

export const HiddenLinks = () => {

    const categories = [...Categories, ...Models];

    return (
        <nav hidden style={{display: 'none'}}>
            {categories.map(d => {
                return <a key={d.path} rel="noopener" href={d.path} >{d.name}</a>
            })}
        </nav>
    )
}
