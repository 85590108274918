import React from 'react'

import { CategoryButton } from '../../Components/Buttons/CategoryButton/CategoryButton'

export const SortOptions = () => {

    const sort = new URLSearchParams(window.location.search).get('sort');

    return (
        <>
        <CategoryButton path="?sort=new" state={sort === 'new'} sort={true} name='New' icon={<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M28.0349 18.516L25.4444 16.209C24.6944 15.5415 24.6944 14.448 25.4444 13.7805L28.0349 11.472C28.7849 10.8045 28.5854 10.1835 27.5909 10.086L24.1559 9.76051C23.1629 9.66751 22.6259 8.81101 22.9694 7.86151L24.7919 2.78701C25.1339 1.83451 24.7154 1.48951 23.8634 2.01601L19.8119 4.52101C19.6112 4.64675 19.3869 4.73012 19.1529 4.76601C18.9188 4.80189 18.6798 4.78953 18.4507 4.72968C18.2216 4.66984 18.0071 4.56377 17.8204 4.418C17.6338 4.27222 17.479 4.08983 17.3654 3.88201L15.8984 1.26301C15.4049 0.381014 14.5904 0.378014 14.0879 1.25101L12.7244 3.62101C12.2189 4.49851 11.0624 4.87051 10.1549 4.45501L7.75489 3.35401C6.84289 2.93401 6.14539 3.41701 6.19939 4.42801L6.32839 6.85051C6.38389 7.86151 5.63689 8.90101 4.67239 9.16201L2.35489 9.78301C1.39189 10.044 1.21489 10.803 1.96489 11.4705L4.55539 13.779C5.30539 14.4465 5.30539 15.54 4.55539 16.2075L1.96489 18.516C1.21489 19.1835 1.41289 19.8315 2.40439 19.9515L5.54689 20.3325C6.53839 20.4525 7.09039 21.3375 6.77389 22.299L5.16289 27.186C4.84639 28.1475 5.29789 28.5195 6.16489 28.0185L9.85939 25.8795C10.2904 25.6408 10.7949 25.5713 11.2744 25.6845C11.7539 25.7976 12.174 26.0853 12.4529 26.4915L13.9859 28.8C14.5439 29.6385 15.3824 29.5935 15.8459 28.698L17.1944 26.0985C17.4189 25.6672 17.803 25.3407 18.2648 25.1886C18.7265 25.0364 19.2295 25.0707 19.6664 25.284L22.2674 26.5845C23.1644 27.03 23.8529 26.5695 23.7989 25.5615L23.6684 23.136C23.6159 22.125 24.3599 21.0885 25.3274 20.8275L27.6419 20.2035C28.6079 19.9425 28.7849 19.1835 28.0349 18.516ZM16.4999 21H13.4999V18H16.4999V21ZM16.4999 16.5H13.4999V9.00001H16.4999V16.5Z" fill="#F8F8F8"/>
</svg>
} />
        <CategoryButton path="" sort={true} state={!sort ? true : false} name="Rising" icon={<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_166_6)">
<path d="M21.2499 6.875C20.7526 6.875 20.2757 7.07254 19.9241 7.42417C19.5724 7.77581 19.3749 8.25272 19.3749 8.75C19.3749 9.24728 19.5724 9.72419 19.9241 10.0758C20.2757 10.4275 20.7526 10.625 21.2499 10.625H21.7237L17.4999 14.8487L13.1999 10.5488C12.8483 10.1976 12.3718 10.0004 11.8749 10.0004C11.378 10.0004 10.9015 10.1976 10.5499 10.5488L2.42491 18.6738C2.08319 19.0272 1.89397 19.5008 1.89801 19.9924C1.90205 20.484 2.09902 20.9544 2.4465 21.3022C2.79398 21.65 3.26416 21.8474 3.75578 21.8519C4.2474 21.8564 4.72112 21.6676 5.07491 21.3263L11.8749 14.5263L16.1749 18.8262C16.5265 19.1774 17.003 19.3746 17.4999 19.3746C17.9968 19.3746 18.4733 19.1774 18.8249 18.8262L24.3749 13.2763V13.75C24.3749 14.2473 24.5725 14.7242 24.9241 15.0758C25.2757 15.4275 25.7526 15.625 26.2499 15.625C26.7472 15.625 27.2241 15.4275 27.5757 15.0758C27.9274 14.7242 28.1249 14.2473 28.1249 13.75V8.75C28.1249 8.25272 27.9274 7.77581 27.5757 7.42417C27.2241 7.07254 26.7472 6.875 26.2499 6.875H21.2499Z" fill="#F8F8F8"/>
</g>
<defs>
<clipPath id="clip0_166_6">
<rect width="30" height="30" fill="white"/>
</clipPath>
</defs>
</svg>
} />
        <CategoryButton
        path='?sort=best'
        state={sort === 'best'}
        sort={true}
        icon={<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.0752 14C21.7877 13.625 21.4377 13.3 21.1127 12.975C20.2752 12.225 19.3252 11.6875 18.5252 10.9C16.6627 9.075 16.2502 6.0625 17.4377 3.75C16.2502 4.0375 15.2127 4.6875 14.3252 5.4C11.0877 8 9.81268 12.5875 11.3377 16.525C11.3877 16.65 11.4377 16.775 11.4377 16.9375C11.4377 17.2125 11.2502 17.4625 11.0002 17.5625C10.7127 17.6875 10.4127 17.6125 10.1752 17.4125C10.1038 17.3535 10.0444 17.2814 10.0002 17.2C8.58768 15.4125 8.36268 12.85 9.31268 10.8C7.22518 12.5 6.08768 15.375 6.25018 18.0875C6.32518 18.7125 6.40018 19.3375 6.61268 19.9625C6.78768 20.7125 7.12518 21.4625 7.50018 22.125C8.85018 24.2875 11.1877 25.8375 13.7002 26.15C16.3752 26.4875 19.2377 26 21.2877 24.15C23.5752 22.075 24.3752 18.75 23.2002 15.9L23.0377 15.575C22.7752 15 22.0752 14 22.0752 14ZM18.1252 21.875C17.7752 22.175 17.2002 22.5 16.7502 22.625C15.3502 23.125 13.9502 22.425 13.1252 21.6C14.6127 21.25 15.5002 20.15 15.7627 19.0375C15.9752 18.0375 15.5752 17.2125 15.4127 16.25C15.2627 15.325 15.2877 14.5375 15.6252 13.675C15.8627 14.15 16.1127 14.625 16.4127 15C17.3752 16.25 18.8877 16.8 19.2127 18.5C19.2627 18.675 19.2877 18.85 19.2877 19.0375C19.3252 20.0625 18.8752 21.1875 18.1252 21.875Z" fill="#F8F8F8"/>
        </svg>
        }
        name="Best" /> 
        </>
    )
}
