import React from 'react'

import { Helmet } from 'react-helmet'
import { Categories } from '../../Util/Categories';
import { Models } from '../../Util/Models';

export const MetaTags = ({data = {}, image}) => {

    let img = "https://res.cloudinary.com/drlkgoter/image/upload/v1701370801/Screenshot_2023-11-30_110002_khmrdt.png";

    let title = "XQuicky - The Ultimate Destination for TikTok Style Porn Videos";

    let description = "Indulge in the hottest TikTok style porn videos featuring beautiful models. XQuicky is your go-to destination for high-quality, explicit content that will leave you breathless. Experience the thrill of TikTok style porn like never before!";

    const category = [...Categories, ...Models];

    const inCategory = category.find(c => {
        if (c.path === window.location.pathname && c.path !== '/') {
            return c.name;
        } else {
            return null;
        }
    })

    return (
    <Helmet>
        <meta charSet='utf-8' />
        <title>{inCategory?.name ? `XQuicky: ${inCategory?.name}` : data?.title ? `XQuicky: ${data.title}` : 'XQuicky'}</title>
        <link rel='canonical' href={window.location.href.split('?')[0]} />
        <meta property='og:image' name='og:image' content={image || img} />
        <meta name='og:title' content={inCategory?.name ? `XQuicky: ${inCategory?.name}` : data?.title ? `XQuicky: ${data.title}` : title} />
        <meta property='twitter:image' name='twitter:image' content={image || img} />
        <meta name='twiiter:title' content={inCategory?.name ? `XQuicky: ${inCategory?.name}` : data?.title ? `XQuicky: ${data.title}` : title} />
        <meta name="description" content={data.title || description} />
        <meta name="og:description" content={data.title || description} />
        <meta name="twitter:description" content={data.title || description} />
        
    </Helmet> 
    )
}
