
export const Models = [
    {
        name: "Rachel Cook",
        path: "/rachelcook",
        state: ["RachelCook"],
        image: "https://styles.redditmedia.com/t5_39ra2/styles/communityIcon_zqoe219jw7fa1.jpg"
    },
    {
        name: "Helga Lovekaty",
        path: "/helgalovekaty",
        state: ["helgalovekaty"],
        image: "https://styles.redditmedia.com/t5_2y03t/styles/communityIcon_o8utgxzww9b81.png?width=256&s=0d56d52eb5e0544f4b99afaedeb63deace883c65"
    },
    {
        name: "Emily Willis",
        path: "/emilywillis",
        state: ["EmilyWillis"],
        image: "https://preview.redd.it/tykgzfwy7l4c1.jpg?width=640&crop=smart&auto=webp&s=299806f2a8d72e8f25b0f0ef8b7ff0c5608ca70d"
    },
    {
        name: "Nata Lee",
        path: "/natalee",
        state: ["NataLee"],
        image: "https://a.thumbs.redditmedia.com/p5L3mqPyLbaNDBvZ3eDdkyLVoRAwTk6do1nDfQBM8-4.png",
    },
    {
        name: "Natalie Roush",
        path: "/natalieroush",
        state: ["NatalieRoush"],
        image: "https://styles.redditmedia.com/t5_4bnvt/styles/communityIcon_ytrwx5zq5dha1.jpg?width=256&format=pjpg&s=20213d7792d3d467b9dd149a02ea1b87c7b07da5"
    },
    {
        name: "Polina Malinovskaya",
        path: "/polinamalinovskaya",
        state: ["polinamalinovskaya"],
        image: "https://styles.redditmedia.com/t5_3h889/styles/communityIcon_wiibk8up8z141.jpg?width=256&format=pjpg&s=f156180017b461442777e5ded87907bfce4eefda"
    },
    {
        name: "Eva Elfie",
        path: "/evaelfie",
        state: ["EvaElfie"],
        image: "https://styles.redditmedia.com/t5_wmiye/styles/communityIcon_dsu3kuy47kec1.png"
    },
    {
        name: "LuxuryGirl",
        path: "/luxurygirl",
        state: ["LuxuryGirl"],
        image: "https://styles.redditmedia.com/t5_yopd5/styles/communityIcon_yfuo2uhzqdb61.png?width=256&s=b42b64e7fc8a465d0b21525c9cc446705f0e980e"
    },
    {
        name: "Alina Lopez",
        path: "/alinalopez",
        state: ["AlinaLopez"],
        image: "https://styles.redditmedia.com/t5_3pujl/styles/communityIcon_i5grrk6g40g21.jpg?width=256&format=pjpg&s=9be1a8eaf7879c879192794108f9b51b0558d5b5"
    },
    {
        name: "Kenzie Anne",
        path: "/kenzieanne",
        state: ["KenzieAnne"],
        image: "https://styles.redditmedia.com/t5_3lf5au/styles/communityIcon_7pay38jon6591.png?width=256&s=1e45626e8d126bb1571a8205789d9a9a36b3acff"
    },
    {
        name: "Little Caprice",
        path: "/littlecaprice",
        state: ["LittleCaprice"],
        image: "https://b.thumbs.redditmedia.com/gF81aLeYbhcwKGzPlaRRTdTJiJQtN4tsvPoLVfKmKuE.png"
    },
    {
        name: "Eva Lovia",
        path: "/evalovia",
        state: ["Evalovia"],
        image: "https://b.thumbs.redditmedia.com/2K8cv0fkQwrcb2OZ6skV2afTYmYhCWDLdJcl9lF51vQ.png"
    },
    {
        name: "Harley Dean",
        path: "/harleydean",
        state: ["HarleyDean"],
        image: "https://styles.redditmedia.com/t5_357rv/styles/communityIcon_xw6v2h0d0ghb1.jpg?width=256&format=pjpg&s=dab7167151606bd834c7854a5a1bfdf21417d6ac"
    },
    {
        name: "Mia Malkova",
        path: "/miamalkova",
        state: ["MiaMalkova"],
        image: "https://styles.redditmedia.com/t5_2vpdt/styles/communityIcon_e1m27uepyy3b1.png?width=256&s=a58ddd9d9b54e07a26ce0f7390a01ccfb3ee2b2a"
    },
    {
        name: "Tori Black",
        path: "/toriblack",
        state: ["Tori_Black"],
        image: "https://b.thumbs.redditmedia.com/ECuSUtawFHT9LYSFmnbxOZ7lsZ5WHjyYFxvy0mdydmI.png"
    },
    {
        name: "Riley Reid",
        path: "/rileyreid",
        state: ["RileyReid"],
        image: "https://styles.redditmedia.com/t5_2uwys/styles/communityIcon_4rel7hgbxc181.png?width=256&s=abcaa2a3a00d9981bbb3770eaab078143b2dc83f"
    },
    {
        name: "Belle Delphine",
        path: "/belledelphine",
        state: ["BelleDelphineGoneWild"],
        image: "https://styles.redditmedia.com/t5_80path/styles/communityIcon_ta0c244166ma1.png?width=256&s=82671b35a94f5df781bc6717b1226832213bbe83"
    },
    {
        name: "Lana Rhoades",
        path: "/lanarhoades",
        state: ["lanarhoades"],
        image: "https://styles.redditmedia.com/t5_3elao/styles/communityIcon_z3s4vw000tm61.jpg?width=256&format=pjpg&s=b4c25ff531bff1381671724419479c8b4bc48a5f"
    },
    {
        name: "KalinkaFox",
        path: "/kalinkafox",
        state: ["KalinkaFox"],
        image: "https://styles.redditmedia.com/t5_pcfjp/styles/communityIcon_9wjm11sfi7p31.jpg?width=256&format=pjpg&s=5815eb2f64e45f389487b66bfa4e849143196715"
    },
    {
        name: "Anna Blossom",
        path: "/annablossom",
        state: ["annablossom"],
        image: "https://preview.redd.it/oe573zhxu1j71.jpg?width=640&crop=smart&auto=webp&s=9d4330bb84f14b9bf8d9fa4c5845bed497c80c62"
    },
    {
        name: "Anne Hathaway",
        path: "/annehathaway",
        state: ["AnneHathaway"],
        image: "https://styles.redditmedia.com/t5_2sa78/styles/communityIcon_209adg6qsl371.jpg?width=256&format=pjpg&s=e86d39ba639d2fb954aaef6e0c0aebadd4d46cf9"
    },
    {
        name: "Seltin Sweety",
        path: "/seltinsweety",
        state: ["seltinsweety"],
        image: "https://styles.redditmedia.com/t5_506nnd/styles/communityIcon_95uykz62c3681.png?width=256&s=d019b60daf61f846b115c8d3cc442c0b994cfef3"
    },
    {
        name: "Nancy Ace",
        path: "/nancyace",
        state: ["Nancy_A"],
        image: "https://styles.redditmedia.com/t5_3gwfh/styles/communityIcon_8g3mmshf9tx71.png?width=256&s=5b782c52db4d156d32c99ef522b6dee229289d0f"
    },
    {
        name: "Sonya Blaze",
        path: "/sonyablaze",
        state: ["SonyaBlazeX"],
        image: "https://styles.redditmedia.com/t5_4jmm16/styles/communityIcon_xhwwgrz8as481.png?width=256&s=da1513db6f67dd041de4b0ba13570ffad518932f"
    },
    {
        name: "Julia Römmelt",
        path: "/juliaroemmelt",
        state: ["JuliaRoemmelt"],
        image: "https://styles.redditmedia.com/t5_343hmz/styles/communityIcon_ketaawly4ym51.png?width=256&s=c9bc7975829a73f1596633f873bf7e468a9a4ec6"
    },
    {
        name: "Olga Alberti",
        path: "/olgaalberti",
        state: ["Olga_Alberti"],
        image: "https://external-preview.redd.it/BryA_G9FX7m53B3Kp0hAcbCyhfoSqcSjNRwaD8LErQ8.jpg?auto=webp&s=b62a21fc5bf8874ec488a6e23f00a33d4a33097c"
    },
    {
        name: "Sasha Grey",
        path: "/sashagrey",
        state: ["Sashagrey"],
        image: "https://styles.redditmedia.com/t5_2rspl/styles/communityIcon_a8za7k7pqs8b1.jpg?width=256&format=pjpg&s=6c807003b13b568883c84eadda9faa75c61b7ce3"
    },
    {
        name: "Ariana Marie",
        path: "/arianamarie",
        state: ["arianamarie"],
        image: "https://styles.redditmedia.com/t5_32ps0/styles/communityIcon_jafqdshhhl761.png?width=256&s=d5f90f3642d841e63ad43f2b5fc99d5a8825ee65"
    },
    {
        name: "Liya Silver",
        path: "/liyasilver",
        state: ["LiyaSilver"],
        image: "https://styles.redditmedia.com/t5_ma5ad/styles/communityIcon_08ybusy5op181.png?width=256&s=f00247f6cf28dcca83e4e03aef852234da6c0078"
    },
    {
        name: "Cherry Crush",
        path: "/cherrycrush",
        state: ["MyCherryCrush"],
        image: "https://styles.redditmedia.com/t5_2url3/styles/communityIcon_vc307ns3jds81.jpg?width=256&format=pjpg&s=13c4670337c32977289268116e75b79c08eee6bf"
    }

].sort(function(a, b) {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
});